<template>
  <div>
    <van-popup v-model="show" :safe-area-inset-bottom="true" :overlay="showCover" @close="closePopup" position="bottom"
      :close-on-click-overlay="false" :lock-scroll="true" :closeable="true">
      <div class="content">
        <div class="title">{{ payData.boxTitle ? payData.boxTitle : '确认订单' }}</div>
        <div class="name row-between-center">
          <div class="left">商品名称</div>
          <div class="left fw6 l-name ellipsis-1">{{ payData.subject ? payData.subject : '' }}</div>
        </div>
        <div v-if="payData.useTips" class="goodsNum row-between-center">
          <div class="left">使用门店范围</div>
          <div class="left fw6">{{ payData.useTips }}</div>
        </div>
        <div v-if="payData.originalPriceStr" class="goodsNum row-between-center">
          <div class="left">原价</div>
          <div class="left fw6">¥{{ payData.originalPriceStr }}</div>
        </div>
        <div v-if="payData.totalAmount" class="goodsNum row-between-center">
          <div v-if="payData.firstPrice && !payData.extendsJson.giveUpFirstBuy" class="left">新客专享价</div>
          <div v-else class="left">限时售价</div>
          <div class="left fw6">¥{{ payData.totalAmount }}</div>
        </div>
        <!-- <div class="c-container" v-if="list.length > 0">
          <div class="coupon-item">优惠立减</div>
          <div @click="selectCoupon()" class="coupon row-between-center">
            <div class="left">优惠券</div>
            <div v-if="list.length && selectedDiscount"><span class="s2">-¥</span><span class="s3">{{ selectedDiscount
            }}</span></div>
          
            <div v-else-if="payData.firstPrice && !payData.extendsJson.giveUpFirstBuy">暂无可用优惠券</div>
            <div v-else-if="list.length"><span class="s1">有{{ list.length }}张可用</span></div>
            <div v-else>暂无优惠券</div>
          </div>
        </div> -->

        <div class="pay-type">选择支付方式</div>
        <!--支付宝支付-->
        <div v-if="appTypeStr !== 'mini'" @click="payType = 2" class="alipay row-between-center"
          :class="{ 'active': payType === 2 }">
          <div class="left"><img
              src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/alipay.png"
              alt="">支付宝支付<span v-if="submitMoney >= 1000">(花呗支付三期免息)</span>
          </div>
        </div>
        <div @click="payType = 1" class="wechat row-between-center" :class="{ 'active': payType === 1 }">
          <div class="left"><img
              src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/wechatpay.png" alt="">
            微信支付
          </div>
        </div>


        <!-- 云闪付 -->
        <!-- <div v-if="appTypeStr !== 'mini' && compareVersion(version, '4.35.10') >= 0" @click="payType = 3"
          class="wechat row-between-center" :class="{ 'active': payType === 3 }">
          <div class="left"><img src="https://img.chaolu.com.cn/ACT/icon/unionpay2.png" alt="">
            云闪付支付
          </div>
        </div> -->
        <div @click="pay()" class="pay-btn row-center-center fw6">￥{{ submitMoney }} 去支付</div>
      </div>
    </van-popup>
    <coupon :discount="selectedDiscount" :unavailableList="unavailableList" :couponId="selectedCouponId" :list="list"
      @confirm="confirmVoucher" :showCoupon.sync="showCoupon"></coupon>
  </div>
</template>
<script>
import userMixin from "@/mixin/userMixin";
import coupon from "./coupon";
import { compareVersion } from "@/lib/utils"
import { silentAppPay, getAppVersion } from '@/lib/appMethod'
import { accAdd, subtr } from '@/lib/utils'
export default {
  mixins: [userMixin],
  props: {
    showCover: {
      type: Boolean,
      default: true
    },
    showPayPopup: {
      type: Boolean,
      default: false
    },
    payData: {
      type: Object,
      default() { return {} }
    },
    payUrl: {
      type: String,
      default: ''
    }
  },
  components: { coupon },
  data() {
    return {
      show: false,
      payType: 1, // 支付类型 默认1， 只在小程序内使用，默认只有微信
      showCoupon: false,
      // 鹿充券列表
      list: [],
      unavailableList: [],
      // 支付参数
      selectedDiscount: 0,
      selectedCouponId: '',
      // submitMoney: 0 // 最终支付金额
      hasAdd: false,
      addData: {}, // 加购数据
      goodDetail: {}, // 商品数据
      version: ''
    };
  },
  computed: {
    submitMoney() {
      if (!this.selectedDiscount || !this.selectedCouponId) {
        return this.payData.totalAmount
      }
      let p = (this.payData.totalAmount - this.selectedDiscount) > 0 ? (this.payData.totalAmount - this.selectedDiscount) : 0
      return p.toFixed(2)
    }
  },
  async created() {
    this.version = await getAppVersion()
    this.payType = this.appTypeStr === 'mini' ? 1 : 2

  },
  methods: {
    compareVersion,
    accAdd, subtr,
    closePopup() {
      this.showCoupon = false
      this.show = false
      this.addData = {}
      this.hasAdd = false
      this.list = []
      this.$emit('update:showPayPopup', false)
    },
    selectCoupon() {
      if (this.payData.firstPrice && !this.payData.extendsJson.giveUpFirstBuy) {
        return false
      }
      this.showCoupon = true
    },
    getCashVoucher() {
      return this.$axios.post(`${this.baseURLApp}/voucher/equityCardVoucher`, {
        userId: this.userId,
        token: this.token,
        cardItemIds: [this.payData.mouldId],
      }, false).then(res => {
        let data = res.data.list ? res.data.list[0] : {}
        this.list = data.availableList ?? []  // 可用列表
        return data
      })
    },
    getCashVoucher2() {
      return this.$axios.post(`${this.baseURLApp}/cashVoucher/user/listUserUseable`, {
        cityId: this.payData.cityId,
        goodsId: this.payData.mouldId,
        payableAmount: this.payData.totalAmount,
        type: this.payData.type,
        userId: this.userId,
        token: this.token,
      }, false).then(res => {
        let data = res.data[0] ?? {}
        this.list = res.data ?? []  // 可用列表
        return data
      })

    },
    confirmVoucher(item) {
      if (item) {
        this.selectedDiscount = item.discount || 0
        this.selectedCouponId = item.id || ''
      }
    },
    pay() {
      let payParam = JSON.parse(JSON.stringify(this.payData)), url = this.payUrl
      payParam.totalAmount = this.submitMoney
      payParam.discountId = this.selectedCouponId
      payParam.extendsJson.discountId = this.selectedCouponId
      // this.show = false
      silentAppPay(this.payType, payParam, url)
    },
  },
  watch: {
    async showPayPopup(val) { // 显示隐藏
      this.show = val
      if (val) {
        // 优惠券金额和id 如果从父级传进来就取父级的 没有就取可用优惠券列表返回的最大值
        let r = ''
        if (this.payData.type == 24 || this.payData.type == 25) {
          r = await this.getCashVoucher()
        } else {
          r = await this.getCashVoucher2()
        }
        if (this.payData.firstPrice && !this.payData.extendsJson.giveUpFirstBuy) {
          this.selectedDiscount = this.payData.selectedDiscount || 0
          this.selectedCouponId = this.payData.discountId || ''
        } else {
          this.selectedDiscount = r.maxDiscount || r.discount
          this.selectedCouponId = r.maxDiscountVoucherId || r.cashVoucherUserId
        }
        this.goodDetail = JSON.parse(JSON.stringify({
          payData: this.payData,
          selectedDiscount: this.selectedDiscount,
          selectedCouponId: this.selectedCouponId,
        }))
      } else {
        this.closePopup()
      }
    },

  },
};
</script>
<style lang="less" scoped>
.content {
  padding: 0 32px 32px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.coupon-box {
  background: #F5F5F5;
  padding-bottom: 0;
  height: 826px;
}

.pay-btn {
  width: 686px;
  height: 96px;
  background: #FFDE00;
  border-radius: 8px;
  color: #242831;
  font-size: 32px;
  margin-top: 72px;
  margin-bottom: 38px;
}

.coupon-item {
  color: #242831;
  font-size: 24px;
  font-weight: bold;
  padding: 32px 0 18px;
  border-top: 1px solid #E6E6E6;
}

.coupon-item-tips {
  color: #242831;
  font-size: 24px;
}

.coupon {
  height: 100px;
  box-sizing: border-box;
  //border-top: 1px solid #E6E6E6;
  border-bottom: 1px solid #E6E6E6;
  background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/arrow-right.png") no-repeat center right;
  background-size: 24px 24px;
  padding-right: 34px;
  margin-top: 12px;
  color: #9AA1A9;
  font-size: 24px;

  .coupon-price-red {
    color: #FB732E;
  }

  .s1 {
    font-weight: bold;
    color: #242831;
    font-size: 24px;
  }

  .s2 {
    font-family: BebasNeueBold;
    font-size: 32px;
    font-weight: bold;
    color: #F03C18;
    margin-right: 4px;
  }

  .s3 {
    font-family: BebasNeueBold;
    color: #F03C18;
    font-size: 40px;
    font-weight: bold;
  }
}

.coupon-title {
  font-weight: bold;
  color: #242831;
  font-size: 36px;
  padding-left: 56px;
  height: 144px;
  display: flex;
  align-items: center;
  background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/arrow-left.png") no-repeat center left;
  background-size: 40px 40px;
}

.coupon-list {
  height: calc(100% - 144px);
  overflow-y: scroll;
}

.coupon-list::-webkit-scrollbar {
  display: none;
}

.price {
  padding: 28px 0 32px;
}

.price>div .s1 {
  text-decoration: line-through;
  color: #6C727A;
  line-height: 28px;
  font-size: 24px;
  margin-right: 16px;
}

.price>div .s2 {
  font-family: BebasNeueBold;
  color: #242831;
  font-size: 24px;
  font-weight: bold;
}

.price>div .s3 {
  font-family: BebasNeueBold;
  color: #242831;
  font-size: 40px;
  font-weight: bold;
}

.left {
  font-size: 24px;
  color: #242831;
  line-height: 28px;
}

.l-name {
  width: 75%;
  text-align: right;
}

.title {
  color: #242831;
  font-size: 36px;
  font-weight: bold;
  padding: 48px 0;
}

.pay-type {
  color: #242831;
  font-size: 24px;
  font-weight: bold;
  padding: 32px 0 8px;
}

.wechat,
.alipay {
  background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/normal.png") no-repeat center right;
  background-size: 40px 40px;
  padding: 24px 0;

  &.active {
    background-image: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/active.png");
  }

  div {
    display: flex;
    align-items: center;
  }

  img {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
}

.coupon-box .item {
  background-color: white;
}

.coupon-box .item {
  margin-bottom: 32px;
}

.coupon-box .item-i {
  display: flex;
  justify-content: space-between;
  //align-items: center;
}

.coupon-box .item-l {
  width: 192px;
  height: 200px;
  background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/card-coupon-active.png");
  background-size: 100% 100%;
  color: white;
  font-size: 20px;
  flex-shrink: 0;
}

.coupon-box .item-l>div:first-child {
  font-family: BebasNeueBold;
  font-size: 80px;
}

.coupon-box .item-l>div:first-child span {
  font-size: 36px;
}

.coupon-box .item-r {
  flex-grow: 1;
  box-sizing: border-box;
  padding-left: 16px;
  position: relative;
}

.coupon-box .item-r .coupon-tabs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;


}

.coupon-box .item-r .coupon-tabs span {
  padding: 4px 8px;
  font-weight: bold;
  color: #FB732E;
  font-size: 18px;
  border: 1px solid #FB732E;
  border-radius: 4px;
  margin-bottom: 8px;
  margin-right: 8px;
}

.coupon-box .item-r .coupon-date {
  margin-top: 38px;
  font-size: 20px;
  line-height: 23px;
  color: #6C727A;
  position: absolute;
  left: 16px;
  bottom: 24px;

}

.coupon-box .item-r .coupon-name {
  line-height: 28px;
  font-weight: bold;
  font-size: 24px;
  padding-top: 24px;
}

.goodsNum {
  margin-top: 48px;
}

.coupon-box .item-r .arrow {
  position: absolute;
  right: 4px;
  bottom: 8px;
  width: 24px;
  height: 24px;
  transition: transform .5s;
  padding: 20px;
}

.coupon-box .arrow.rotate {
  transform: rotateZ(180deg);
}

.c-container {
  margin-top: 48px;
}

.add {
  padding: 36px 0px;
  border-bottom: 1px solid #E6E6E6;

  &.line {
    border-top: 1px solid #E6E6E6;
    margin-top: 36px;
  }

  p {
    display: flex;
    align-items: center;
  }

  .line1 {
    font-size: 24px;
    font-weight: 600;

    .tag {
      background: #FFE4DF;
      color: #F03C18;
      font-size: 20px;
      padding: 2px 9px;
      border-radius: 4px;
      margin-left: 15px;
    }
  }

  .line2 {
    margin-top: 30px;
    font-size: 28px;
    line-height: 42px;
    font-weight: 600;
    background: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/normal.png") no-repeat center right;
    background-size: 40px 40px;

    &.active {
      background-image: url("https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/cash-coupon/active.png");
    }
  }

  .line3 {
    margin-top: 16px;
    font-size: 20px;

    s {
      color: #6C727A;
    }
  }

  .line2,
  .line3 {
    >span:first-child {
      width: 75%;
      margin-right: 22px;
    }
  }

  .line3 {
    >span:first-child {
      white-space: pre-wrap;
    }
  }
}
</style>

