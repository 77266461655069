<template>
  <div>
    <!-- 沉浸式头部 -->
    <nav-bar :header-title="AI.activityName" backColor="#fff" :show-back="true" :header-back="newAppBack"
      @setBarHeight="setBarHeight" />
    <!-- 预告 -->
    <div v-if="AI.activityTrailer" class="marketing-preview col-start-center">
      <img :src="item" v-for="item in AI.activityTrailer.imgList" :key="item" style="width: 100%" alt="" />
      <van-count-down v-if="AI.activityTrailer.previewTime" style="opacity: 0" @finish="previewFinish"
        :time="AI.activityTrailer.previewTime" />
    </div>
    <!-- 主会场 -->
    <div v-else class="marketing-template-deposit" :style="`background-color: ${AI.shopStyle.backgroundColor};`">
      <div class="background">
        <!-- 主视觉 -->
        <img :src="AI.shopStyle.headerMainImg" class="head-img" />
        <div class="top-button">
          <!-- 分享按钮 -->
          <template v-if="AI.shopStyle.shareStatus">
            <template v-if="AI.shopStyle.shareIconImg">
              <img class="icon" :src="AI.shopStyle.shareIconImg" @click="showShare" alt="" />
            </template>
            <template v-else>
              <div class="button f24" :style="`background: ${AI.shopStyle.shareIconColor}`" @click="showShare">分享</div>
            </template>
          </template>
          <!-- 规则按钮图标 -->
          <template v-if="AI.shopStyle.ruleStatus">
            <template v-if="AI.shopStyle.ruleIconImg">
              <img class="icon" :src="AI.shopStyle.ruleIconImg" @click="openRule(true)" alt="" />
            </template>
            <template v-else>
              <div class="button f24" :style="`background: ${AI.shopStyle.ruleIconColor}`" @click="openRule(true)">规则
              </div>
            </template>
          </template>
        </div>
        <!-- 右上角的悬浮按钮 -->
        <img v-if="showFloat" @click="openDJ" src="https://img.chaolu.com.cn/ACT/double11-2024/deposit/main.png" alt=""
          class="float-icon" />
        <count-down v-if="AI.shopStyle.headerCountdown" :timeObj="AI" :status="activityStatus"
          @timeFinish="timeFinish"></count-down>
      </div>

      <!-- banner -->
      <div class="banner-block row-between-center">
        <img src="https://img.chaolu.com.cn/ACT/double11-2024/deposit/banner1.png" alt=""
          :class="['small-banner', 'flex-auto']" />
      </div>

      <!-- 带货人 信息 -->
      <div class="invite-block row-start-center f22"
        v-if="AI.shopStyle.inviterStatus && (inviteUserInfo.shareTeacherId || inviteUserInfo.shareUserId)">
        <div class="invite-head"><img :src="`${inviteUserInfo.shareUserHeadImg}`" alt="" /></div>
        <div class="invite-name ellipsis" :style="`color: ${AI.shopStyle.subfieldNameColor};`">
          <span>{{ inviteUserInfo.shareTitle }}</span> 邀请你参与{{ AI.activityName }}
        </div>
      </div>

      <!-- 分区标题图 -->
      <img v-if="AI.shopStyle.partitionTitleImg" :src="AI.shopStyle.partitionTitleImg" alt="" class="nav-title" />
      <!-- 商品导航 -->
      <div></div>
      <van-sticky v-if="AI.partitions.subfieldsInfos && AI.partitions.subfieldsInfos.length > 1" style="width: 100%"
        :offset-top="barHeight" z-index="999">
        <div class="nav row-between-center flex-wrap" ref="nav">
          <!-- <div class="nav-top "> -->
          <div
            :class="['tm', 'col-center-start', AI.partitions.subfieldsInfos.length % 3 === 0 || (AI.partitions.subfieldsInfos.length === 5 && index > 1) ? 'small' : '', tabIndex === index ? 'active' : '']"
            :style="`background: linear-gradient(180deg, ${tabIndex === index ? AI.shopStyle.clickSubfieldMenuStyle.bgTopColor : AI.shopStyle.defaultSubfieldMenuStyle.bgTopColor} 0%, ${tabIndex === index ? AI.shopStyle.clickSubfieldMenuStyle.bgBottomColor : AI.shopStyle.defaultSubfieldMenuStyle.bgBottomColor
              } 100%);
                  color: ${tabIndex === index ? AI.shopStyle.clickSubfieldMenuStyle.textColor : AI.shopStyle.defaultSubfieldMenuStyle.textColor}`"
            v-for="(item, index) in AI.partitions.subfieldsInfos" :key="index" @click="setTabIndex(index)">
            <div class="fw6 f28">{{ item.menuName }}</div>
            <div class="ii f24">{{ item.light }}</div>
          </div>
          <!-- </div> -->
        </div>
      </van-sticky>

      <div class="template-content" ref="refContent">
        <div v-if="AI.partitions.subfieldsInfos && AI.partitions.subfieldsInfos.length > 1"
          class="title-line row-between-center f20" :style="`color: ${AI.shopStyle.subfieldNameColor};`">
          <p class="subfield-name puhui">{{ curGoods.subfieldName }}</p>
          <div class="row-start-center" @click="openRule(false)">
            <span class="ss">规则</span>
            <img class="triangle" src="https://img.chaolu.com.cn/ACT/anniversary-marketing-2024/triangle.png" alt="">
          </div>
        </div>

        <!-- 分栏 菜单  -->
        <van-sticky v-if="curGoods.showGroupMenu && curGoods.groupInfos.length > 1" :offset-top="barHeight + navHeight"
          z-index="999">
          <div class="group-bar row-start-center" ref="groupBox">
            <div class="group-bg">
              <template v-for="(item, index) in curGoods.groupInfos">
                <div v-show="item.show"
                  :class="['item', 'row-center-center', 'f28', 'fw6', curGoods.groupInfos.length > 5 ? 'flex-none' : 'flex-auto', groupIndex == index ? 'active' : '']"
                  @click="toGroup(`group${item.id}`, index)" :ref="`item${item.id}`" :key="index">
                  {{ item.groupMenuName }}
                </div>
              </template>
            </div>
          </div>
        </van-sticky>


        <!-- 分组上部广告位 -->
        <div class="ad-group col-start-center"
          v-if="curGoods.promotionalInfo.status && curGoods.promotionalInfo.topAds">
          <img :src="item.imgUrl" @click="toAppPath(item.url)" v-for="item in curGoods.promotionalInfo.topAds"
            :key="item.imgUrl" alt="" class="ad-item" />
        </div>
        <template v-for="group in curGoods.groupInfos">
          <div :key="group.id" :ref="`group${group.id}`">
            <!-- 分组的标题 -->
            <div v-if="curGoods.groupInfos.length > 1" class="group-name f30 fw6 row-start-center">
              {{ group.groupName }}
            </div>
            <div v-if="group.goodsInfos.length && group.show"
              :class="['good-group', 'flex-wrap', group.onlyImgMode ? 'only-img row-start-start' : 'row-between-start']"
              :key="group.id">
              <!-- 一行一个 -->
              <template v-if="group.listStyle === '1' && !group.onlyImgMode">
                <style-one-card v-show="showWithCrow(good)" :cardObj="good" :shopStyle="AI.shopStyle"
                  :status="goodsStatusMap[good.id]" :priceStatus="goodsPriceStatusMap[good.id]"
                  :activityStatus="activityStatus" v-for="good in group.goodsInfos" :key="good.id"
                  @buyCard="buyCard(good)"></style-one-card>
              </template>
              <template v-if="group.listStyle === '1' && group.onlyImgMode">
                <img v-show="showWithCrow(good)" class="one-img"
                  :src="goodsStatusMap[good.id] === 'NOT_STARTED' ? good.cardImgIneffective : good.cardImgEffective"
                  v-for="good in group.goodsInfos" :key="good.id" @click="buyCard(good)" alt="" />
              </template>

            </div>
          </div>
        </template>

        <!-- 分组下部广告位 -->
        <div class="ad-group col-start-center"
          v-if="curGoods.promotionalInfo.status && curGoods.promotionalInfo.bottomAds">
          <img :src="item.imgUrl" @click="toAppPath(item.url)" v-for="item in curGoods.promotionalInfo.bottomAds"
            :key="item.imgUrl" alt="" class="ad-item" />
        </div>

      </div>
      <div class="more">
        <img src="https://img.chaolu.com.cn/ACT/double11-2024/deposit/more.png" />
      </div>

      <MyDeposit ref="refMyDeposit" />
      <van-popup v-model="surePayShow" position="center" class="pop-sure">
        <div class="sure-title puhui">定金确认</div>
        <div class="pay-model">
          <div class="coner">付定可得</div>
          <div class="pay-pic">
            <img :src="sureItem.preGoodsImg" />
          </div>
          <div class="pay-info">
            <div class="pay-name">{{ sureItem.preGoodsName }}</div>
            <div class="pay-time">{{ sureItem.preGoodsExtend }}</div>
            <div class="nosend">不可转赠</div>
            <div class="pay-price">¥{{ sureItem.preGoodsPrice }}</div>
          </div>
        </div>
        <div class="start">11月11日10:00 开启尾款支付通道</div>
        <div class="pay-model">
          <div class="coner">尾款可得</div>
          <div class="pay-pic">
            <img :src="sureItem.afterGoodsImg" />
          </div>
          <div class="pay-info">
            <div class="pay-name">{{ sureItem.afterGoodsName }}</div>
            <div class="pay-time">{{ sureItem.afterGoodsExtend }}</div>
            <div class="pay-price">
              <!-- <span class="after-price">券后价</span> -->
              ¥{{ sureItem.afterGoodsPrice }}
            </div>
          </div>
        </div>
        <div class="sure-sub" @click="whetherMoreItem">确认支付定金 ￥{{ sureItem.preGoodsPrice }}</div>
        <div class="ag">
          <img src="https://img.chaolu.com.cn/ACT/double11-2024/deposit/info.png" class="info" />
          支付即同意<span class="xy" @click="openAgreement">《预售协议》</span>，<b>定金不可退款</b>
        </div>
        <div class="pay-close" @click="surePayShow = false">
          <img src="https://img.chaolu.com.cn/ACT/anniversary-2024/index/close-icon.png" />
        </div>
      </van-popup>
      <van-popup v-model="showAgreement" position="center" class="pop-agreement">
        <div class="a-title">预售协议</div>
        <div class="a-content">
          <div style="text-align: center;"><b>“超鹿运动”2024双十一活动预售协议</b></div>
          <div style=""><b>在同意本协议前请务必审慎阅读、充分理解各条款内容。</b></div>
          <div class="line">
            <b>1、</b>本次活动设有预售，预售期间为<b>2024</b>年<b>10</b>月<b>10</b>日<b>10:00:00</b>至<b>2024</b>年<b>11</b>月<b>10</b>日<b>23:59:59</b>，买家支付定金；
            <b>2024</b>年<b>11</b>月<b>11</b>日<b>10:00:00</b>至<b>2024</b>年<b>11</b>月<b>15</b>日<b>23:59:59</b>
            买家可进行商品尾款支付。
          </div>
          <div class="line"><b>2</b>、预售定金规则：买家支付定金后应自觉遵守担保法的相关规定；支付定金后，因买家自身原因导致未如期支付尾款，或因买家自身原因申请退款的，<b>定金均不退还</b>。
          </div>
          <div class="line">
            <b>3</b>、活动期间内，实付金额大于<b>1000</b>元的商品，买家通过支付宝渠道支付的，支持分三期付款，同时商家将提供贴息服务，即买家分期付款产生的利息（利率为<b>1.80%</b>）由商家承担。
          </div>
          <div class="line">
            4、<b>预售商品不支持退款，在特殊情形下，成功支付全款（定金+尾款）</b>的预售商品若发生退款，退款金额为买家实际支付金额减去买家已消耗商品原价，如买家付款时使用分期支付，<b>商家还将在退款金额中扣除所贴利息。</b>
          </div>
          <div class="line"><b>5、</b>关于预售商品的具体使用规则，见各商品购买页面详情。</div>
          <div class="line">*本次活动定金产品均不可转赠与退款，定金产品购买后即可使用</div>
          <div class="line">参与定金商品如下：通用礼品卡</div>
          <div class="line"><b>6000</b> 元送 <b>2000</b>元【定金<b>1000</b>元】</div>
          <div class="line"><b>10000</b> 元送 <b>3800</b>元【定金<b>2000</b>元】</div>
          <div class="line"><b>20000</b> 元送 <b>9488</b>元【定金<b>3000</b>元】</div>
          <div class="line"><b>50000</b> 元送 <b>27666</b>元【定金<b>5000</b>元】</div>
          <div class="line">私教课包</div>
          <div class="line"><b>36</b>节<b>240</b>元私教课包售价<b>6390</b>元【定金<b>6</b>节 <b>1440</b>元】</div>
          <div class="line"><b>48</b>节<b>240</b>元私教课包售价<b>8410</b>元【定金<b>12</b>节 <b>2880</b>元】</div>
          <div class="line"><b>36</b>节<b>299</b>元私教课包售价<b>7960</b>元【定金<b>6</b>节 <b>1794</b>元】</div>
          <div class="line"><b>48</b>节<b>299</b>元私教课包售价<b>10470</b>元【定金<b>12</b>节 <b>3588</b>元】</div>
          <div class="line"><b>36</b>节<b>360</b>元私教课包售价<b>9590</b>元【定金<b>6</b>节 <b>2160</b>元】</div>
          <div class="line"><b>48</b>节<b>360</b>元私教课包售价<b>12610</b>元【定金<b>12</b>节 <b>4320</b>元】</div>
          <div class="line"><b>36</b>节<b>420</b>元私教课包售价<b>11180</b>元【定金<b>6</b>节 <b>2520</b>元】</div>
          <div class="line"><b>48</b>节<b>420</b>元私教课包售价<b>14710</b>元【定金<b>12</b>节 <b>5040</b>元】</div>
        </div>
        <div class="a-ok" @click="showAgreement = false">我知道了</div>
      </van-popup>

      <!-- 规则 弹窗 -->
      <van-popup v-model="isRuleShow" position="bottom" safe-area-inset-bottom class="pop-rule">
        <div class="pop-rule-title f32 fw6">
          活动规则
          <van-icon name="cross" class="pop-rule-close f28" color="#fff" @click="isRuleShow = false" />
        </div>
        <div class="pop-rule-content f26">
          {{ curRule }}
        </div>
      </van-popup>


      <!-- 海报 组件 -->
      <common-share :value="isShareShow" :shareParams="shareParams" @close="isShareShow = false"
        @share-success="isShareShow = false" :shareItem="['minifriend', 'postermessage', 'save']"
        :miniSharePoster="appTypeStr === 'mini'">
        <template slot="createDom">
          <div class="canvascss">
            <img :src="AI.shareImg" class="canvascss_bg" />
            <div class="canvascss_left">
              <img :src="userDetail.headImg" />
            </div>
            <div class="canvascss_name f26 fw6 ellipsis" :style="`color: ${AI.nickNameHex};`">
              {{ userDetail.nickName }}
            </div>
            <div class="canvascss_right">
              <img v-if="userDetail.qrCode" :src="`data:image/png;base64,${userDetail.qrCode}`" />
            </div>
          </div>
        </template>
      </common-share>

      <!-- 升级提示 -->
      <van-popup v-model="isUpgradeShow" closeable :overlay="true" style="background-color: transparent; width: 84vw"
        closeable>
        <div class="error-box">
          <h4 class="f36 fw6">更新提示</h4>
          <p class="f28">发现新版本，新功能需要升级至最新版本。</p>
          <div @click="upgrade" class="btn f32 fw6 row-center-center">去升级</div>
        </div>
      </van-popup>
      <payPopupCard :showPayPopup.sync="showPayPopup" :pay-data="payData" :pay-url="payUrl"></payPopupCard>
    </div>
  </div>
</template>
<script>
import { newAppBack, initBack, appPay, appPaySuccess, appOpenWeb, silentAppPay, upgrade, getAppVersion } from '@/lib/appMethod'
import MyDeposit from './components/myDeposit.vue'
import navBar from '@/components/nav-bar/nav-bar'
import commonShare from '@/components/commonShare/newShare'
import styleOneCard from './components/style-one-card'
import countDown from './components/count-down'
import userMixin from '@/mixin/userMixin'
import headerMixin from '@/mixin/headerMixin'
import jump from './jump'
import wx from 'weixin-js-sdk'
import payPopupCard from './components/pay-popup-card'

const webPath = `${window.location.origin}/#/superdeer-activity/double11-2024/deposit`
const miniPath = `/pages/webView/index?webUrl=${encodeURIComponent(webPath)}`

const activeId = 10120;
export default {
  components: {
    navBar,
    commonShare,
    styleOneCard,
    countDown,
    MyDeposit,
    payPopupCard
  },
  mixins: [userMixin, headerMixin],
  data() {
    return {
      shopId: '', // 活动id
      AI: {
        // 活动总信息
        activityTrailer: {}, // 预告页
        partitions: {},
        shopStyle: {},
      },
      barHeight: 0,
      navHeight: 0,
      groupHeight: 0,
      tabIndex: 0, // 分栏的下标
      groupIndex: 0, // 分组的下标
      shareParams: {
        title: '超鹿双11提前购！定金抢先花~',
        miniImage: 'https://img.chaolu.com.cn/ACT/double11-2024/deposit/mini.png',
        path: '',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      userDetail: {}, //分享的用户信息
      inviteUserInfo: {}, // 邀请人信息
      isShareShow: false,
      isRuleShow: false,
      isItemSelectShow: false, // 规格选择弹窗
      address: {}, // 实体卡情况下 收货地址
      isItemConfirmShow: false, // 规格确认弹窗
      itemIndex: 0, // 规格下标
      venueSelectShow: false, // 门店的弹窗
      isContinuousShow: false, // 连续包月弹窗
      isZhimaxianxiang: false, // 是否芝麻先享
      isRenewPayTypeShow: false, // 连续包月支付弹窗
      isCombinedBuyShow: false, // 组合购买提示弹窗
      renewPayType: 1,
      isUpgradeShow: false, // 升级提示
      buyGoods: {}, // 当前购买的商品 包含多个规格
      buyItem: {}, // 当前购买的规格
      showPayPopup: false, // 权益卡选择优惠券弹窗
      payData: {}, // 支付参数
      payUrl: '', // 支付回跳链接
      activityStatus: '', // 活动状态
      goodsStatusMap: {}, // 商品对应状态
      goodsPriceStatusMap: {}, // 商品对应的价格状态
      itemStatusMap: {}, // 当前商品下多规格状态
      itemPriceStatusMap: {}, // 当前商品下多规格的价格状态
      curRule: '', // 当前规则 可能是总规则 可能是菜单规则
      topArr: [], // 若存在分组 各个分组的offset集合
      isClickScroll: false,
      isHasActivedCardShow: false, // 已有生效中的卡
      hasActivedTip: '', //
      userExpansionSourceId: '',//获客来源--用拓crm
      surePayShow: false,
      sureItem: {},
      showFloat: false,
      showAgreement: false
    }
  },
  computed: {
    // 当前菜单下的商品 tab
    curGoods() {
      let c = this.AI.partitions.subfieldsInfos ? this.AI.partitions.subfieldsInfos[this.tabIndex] : { groupInfos: [], promotionalInfo: {} }
      // 处理 卡片上 标签问题 逗号隔开的字符改为 数组
      c.groupInfos.map((group) => group.goodsInfos.map((good) => (good.productTags = Array.isArray(good.productTags) ? good.productTags : good.productTags ? good.productTags.split(',') : [])))
      return c
    },
    // 多规格情况下 规格的list
    itemList() {
      return this.buyGoods.itemInfos ? this.buyGoods.itemInfos : []
    },
    // 根据人群、所在城市 判断是否显示
    showWithCrow() {
      return (good) => {
        // 没有showWithCrow或者有的情况下状态可购买时显示
        return !good.showWithCrow || (good.showWithCrow && this.goodsStatusMap[good.id] === 'CAN_BUY')
      }
    },
  },
  async created() {
    initBack()
    await this.$getAllInfo(['userId', 'cityId'])

    const q = this.$route.query
    this.countPoint('405', activeId, '40039', this.userId)
    if (this.appTypeStr === "mini") {
      this.countPoint('408', '408', '40054')
    } else {
      this.countPoint('408', '408', '40053')
    }
    // 存在推荐商品 需要强制登录
    if (q.goodsId && !this.userId) {
      this.$store.commit('openLoginPopup', true)
      return
    }

    // 若是教练邀请 获取教练id
    this.inviteTeacherId = q.teacherId || q.inviteTeacherId
    this.inviteUserId = q.inviteUserId
    this.userExpansionSourceId = q.userExpansionSourceId

    // 来源
    this.from = q.from
    this.queryCityId = q.shopCityId || this.cityId // 区别于mixin里的cityId
    // 本次商城的id
    this.shopId = activeId

    // 分享的链接
    this.shareParams.path = `${miniPath}&id=${this.shopId}`

    // 推荐弹窗  签到或评价会弹的
    this.isSignInRecommended = q.reason ? true : false

    if(this.inviteTeacherId){
      this.countPoint('408', '408', '40052')
    }
    // 获取活动总信息
    const r = await this.getActivity()
    if (r === '0') return
    this.getBuy();
    // 海报的二维码
    if (this.userId) this.initQr()

    // 获取绑定关系
    this.getBindInfo()

    // 小程序卡片分享
    if (this.AI.shopStyle.shareStatus) {
      this.miniShare()
    }

    // 支付成功回调 小程序购买成功提示
    appPaySuccess().then(() => {
      this.paySuccessCallback()
    })
  },
  mounted() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible' && !this.AI.activityTrailer) {
        setTimeout(() => {
          this.getGoodStatus()
          this.getBuy()
        }, 500)
      }
    })
  },
  methods: {
    newAppBack,
    upgrade,
    openDJ() {
      this.countPoint('408', activeId, '40050', this.userId)
      this.$refs.refMyDeposit.show();
    },
    openAgreement() {
      this.showAgreement = true;
    },
    previewFinish() {
      this.getActivity()
    },
    setBarHeight(h) {
      this.barHeight = h || 0
    },
    setTabIndex(v) {
      this.tabIndex = v
      this.getGoodStatus()

      // this.toGroup(this.curGoods.groupInfos[0].id,0)
      // 是否有滚动
      this.setScroll()
    },
    toPath(path) {
      const p = path.split('?')[0]
      let p1 = path.split('?')[1] ? `&${path.split('?')[1]}` : ''

      if (this.inviteTeacherId) {
        p1 = `${p1}&inviteTeacherId=${this.inviteTeacherId}`
        path = `${path}&inviteTeacherId=${this.inviteTeacherId}`
      }
      if (this.inviteUserId) {
        p1 = `${p1}&inviteUserId=${this.inviteUserId}`
        path = `${path}&inviteUserId=${this.inviteUserId}`
      }

      const hasDomain = p.indexOf('http') > -1
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${hasDomain ? `${p}${p1}` : window.location.origin + '/#' + `${p}${p1}`}`,
        })
      }
      if (this.appTypeStr === 'and') {
        App.intentToActivity(
          JSON.stringify({
            className: '.ui.AppWebViewLaunchStandardActivity',
            data: [
              {
                key: 'url',
                value: path,
              },
              {
                key: 'removeTitle',
                value: '1',
              },
            ],
          })
        )
      }
      if (this.appTypeStr === 'ios') {
        hasDomain ? appOpenWeb('', path) : this.$router.push(p)
      }
    },
    toAppPath(obj) {
      if (!obj) return
      if (obj.type === '21') {
        if (this.inviteTeacherId) obj.extendJson.url = `${obj.extendJson.url}&inviteTeacherId=${this.inviteTeacherId}`
        if (this.inviteUserId) obj.extendJson.url = `${obj.extendJson.url}&inviteUserId=${this.inviteUserId}`
      }
      jump(obj)
    },
    async openItemSelect() {
      await this.getItemStatus()

      this.itemIndex = this.buyGoods.itemInfos.length > 1 ? undefined : 0
      this.isItemSelectShow = true
    },

    chooseItem(i, status, item) {
      if (status !== 'CAN_BUY') return
      this.itemIndex = i
      this.buyItem = item
    },

    checkCanBuy() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios
        .post(this.baseURLApp + '/activityShopOrder/checkBuy', {
          goodsId: this.buyGoods.id,
          goodsItemId: this.buyItem.id,
          shopId: this.shopId,
          userId: this.userId,
        })
        .then((r) => {
          this.$toast.clear()
          return r.data
        })
        .catch(() => {
          this.$toast.clear()
          return 0
        })
    },
    async buyCard(item) {
      this.countPoint('407', item.id, '40051', this.userId)
      // 点击购买 基础校验
      // 未登录
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }
      // item可能来自推荐弹窗组件item.isRecommended:true
      // 推荐的商品可能不是当前分组下的商品 所以goodsStatusMap无法使用
      this.buyGoods = item
      this.buyItem = item.itemInfos[this.itemIndex]
      this.buyItem.extendInfo = this.buyItem.extendInfo ? this.buyItem.extendInfo : {}

      if (this.activityStatus != 'in') {
        this.$toast('活动还没开始哦~')
        return
      }
      const res = await this.showGoodsDetail();
      if (!res) { return }
      this.sureItem = res;
      this.surePayShow = true;

    },
    showGoodsDetail() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios
        .post(this.baseURLApp + '/perUseGoods/getPreUseGoodsRelation', {
          id: this.buyGoods.id,
          userId: this.userId,
        })
        .then((r) => {
          this.$toast.clear()
          return r.data
        })
        .catch(() => {
          this.$toast.clear()
          return 0
        })
    },
    async whetherMoreItem() {
      const res = await this.checkCanBuy()
      if (!res) {
        this.$toast('无法购买')
        return
      }
      this.surePayShow = false;
      this.buyWhat()
    },
    async buyWhat() {
      this.payAction()
    },

    async payAction() {

      const payData = this.getPayParams(); console.log(payData);
      // 如果是教练分享单个商品的情况 支付完回到页面还是只能看到单商品的弹窗 因此回调链接加上goodsId
      const goodsId = this.buyGoods.isRecommended ? this.buyGoods.id : ''
      const url = `${this.shareParams.path}&userId=1&goodsId=${goodsId}&shopCityId=${this.queryCityId}`

      // 判断是否芝麻先享 或者 周期付
      switch (this.buyItem.extendInfo.payType) {
        case 'ALI_PRE_ENJOY': // 芝麻先享
          payData.tradeType = 'SUBSCRIPTION' // 按照app的方式
          if (this.appTypeStr === 'mini') { // 小程序环境 跳转待支付页 复制芝麻口令
            const goodsId = this.buyGoods.isRecommended ? this.buyGoods.id : ''
            const url = `${this.shareParams.path}&userId=1&goodsId=${goodsId}&shopCityId=${this.queryCityId}`
            const redirectUri = encodeURIComponent(url)

            wx.miniProgram.navigateTo({
              url: `/pages/webViewPay/index?payData=${JSON.stringify(payData)}&redirect_uri=${redirectUri}`,
            })
          } else {
            silentAppPay(2, payData, this.shareParams.path)
          }
          return
        case 'AUTO_RENEW': // app周期付
          payData.tradeType = this.appTypeStr === 'mini' ? 'APPLET_WITH_CYCLE' : 'APP_WITH_CYCLE'
          silentAppPay(this.renewPayType, payData, this.shareParams.path)
          this.close()
          return
      }

      if (this.appTypeStr === 'mini') {
        payData.wxPayType = 'applet'
      } else {
        appPaySuccess().then(() => {
          this.paySuccessCallback()
        })
      }

      // 弹个选择优惠券的窗
      // if (this.buyGoods.canUseVoucher) {
        this.showPayPopup = true
        this.payData = payData
        this.payUrl = url
      // } else {
      //   appPay(payData, url)
      // }

      this.close()
    },
    getPayParams() {
      return {
        subject: this.buyItem.productName,
        extendsJson: {
          appVersion: '2.1.11',
          shopId: this.shopId,
          activityShopGoodsId: this.buyGoods.id + '',
          activityShopGoodsItemId: this.buyItem.id,
          from: this.from,
          userAddressId: this.address.userAddressId,
          perUseVenueId: this.perUseVenueId,
          // inviteUserId: this.inviteId,
        },
        totalAmount: this.buyItem.salePrice + '',
        venueId: '31',
        goodsNum: [12, 22].includes(this.buyGoods.goodsType) ? '1' : undefined,
        isApp: '1',
        type: this.buyGoods.goodsType, // 礼品卡12  课包10 实体卡22
        userId: this.userId,
        token: this.token,
        extendsType: '4',
        mouldId: this.buyItem.productId,
        whetherPrivacy: 0,
        originalPriceStr: '',
        title: this.appTypeStr === 'mini' ? '商品名称' : this.buyItem.productName,
      }
    },

    async timeFinish() {
      if (this.activityStatus == 'in') {
        this.activityStatus = 'end'
        this.getGoodStatus()
      } else {
        await this.getActivity()
      }
    },
    getBindInfo() {
      this.$axios
        .post(this.baseURLApp + '/userDistribute/bindUser', {
          hasWx: true,
          activityNo: this.shopId, // shopId就是老接口里的activityNo
          userId: this.userId,
          saleTeacherId: this.inviteTeacherId,
          saleUserId: this.inviteUserId,
        })
        .then((res) => {
          const d = res.data
          this.inviteUserInfo = d || {}
          this.inviteTeacherId = d && d.shareTeacherId

          // 2024.04.08 用户拓展的需求 荣仁要求改的 🔽
          this.$axios
            .post(this.baseURLApp + '/userExpansionBing/scanCode', {
              activityId: this.shopId,
              saleTeacherId: this.inviteTeacherId,
              saleUserId: this.inviteUserId,
              userId: this.userId,
              userExpansionSourceId: this.userExpansionSourceId
            })
            .then((res) => {
              if (res.data.code === '999') {
                this.AI = {
                  // 活动总信息
                  activityTrailer: {}, // 预告页
                  partitions: {},
                  shopStyle: {},
                }
                this.$toast('不符合绑定人群，无法访问~')
                setTimeout(() => {
                  this.newAppBack()
                }, 2000)
              }
            })
          // 2024.04.08 用户拓展的需求 荣仁要求改的 🔼
        })
    },

    getActivity() {
      return this.$axios
        .post(`${this.baseURLApp}/activityShopWeb/getShop`, {
          cityId: this.queryCityId,
          shopId: this.shopId,
          userId: this.userId,
        })
        .then((res) => {
          // 活动总信息
          this.AI = res.data
          if (!this.AI.activityTrailer) {
            // 设置活动状态
            this.activityStatus = this.AI.diffStartTime > 0 ? 'notin' : this.AI.diffEndTime > 0 ? 'in' : 'end'
            // 设置页面标题
            document.title = this.AI.activityName
            // 设置小程序卡片分享内容
            this.shareParams.title = this.AI.wxShareContent
            this.shareParams.miniImage = this.AI.wxShareImg || ''
            // 兜底
            if (!this.AI.shopStyle.groupTitleImg) this.AI.shopStyle.groupTitleImg = 'https://img.chaolu.com.cn/ACT/anniversary-2023/coverbg.png'
            // 预加载海报图
            if (this.AI.shareImg) {
              let img = new Image()
              img.src = this.AI.shareImg
              img.onload = () => { }
            }

            this.setTabIndex(0)
            // 获取滚动元素高度
            this.getHeight()
          }
        })
        .catch((res) => {
          this.$toast(res.msg)
          setTimeout(() => {
            this.newAppBack()
          }, 2000)
          return res.code
        })
    },

    // 获取当前菜单tab下商品的id集合 单独获取商品的购买状态 结果用id匹配 {34: 'CAN_BUY'}
    getGoodStatus() {
      const idArr = []
      this.curGoods.groupInfos.map((group) => {
        group.goodsInfos.map((goods) => {
          idArr.push(goods.id)
        })
      })
      const params = {
        goodsId: idArr,
        shopId: this.shopId,
        userId: this.userId,
      }
      // 获取按钮状态
      this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsStatus`, params).then((res) => {
        this.goodsStatusMap = res.data.goodsStatusMap; console.log(this.goodsStatusMap)
        this.$toast.clear()

        // 判断 分栏底下的商品 是否因为人群关系 全部隐藏 此时 需要隐藏整个分栏
        this.curGoods.groupInfos.map((group) => {
          let show = false
          group.goodsInfos.map((goods) => {
            if (!goods.showWithCrow || (goods.showWithCrow && this.goodsStatusMap[goods.id] === 'CAN_BUY')) show = true
          })

          this.$set(group, 'show', show)
        })
      })

      // 获取价格的状态
      // this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodMaxDiscount`, params).then((res) => {
      //   this.goodsPriceStatusMap = res.data.goodsDiscountMap;console.log(res.data)
      // })
    },

    // 获取当前购买商品的规格id集合 结果用id匹配 {34: 'CAN_BUY'}
    getItemStatus() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      const idArr = this.buyGoods.itemInfos.map((item) => item.id)

      const params = {
        goodItems: idArr,
        goodsId: this.buyGoods.id,
        shopId: this.shopId,
        userId: this.userId,
      }
      // 规格价格
      this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodItemMaxDiscount`, params).then((res) => {
        this.itemPriceStatusMap = res.data.goodsDiscountMap
      })
      // 规格按钮状态
      return this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsItemStatus`, params).then((res) => {
        this.itemStatusMap = res.data.goodsStatusMap
        this.$toast.clear()
      })
    },
    getBuy() {
      this.$axios.post(this.baseURLApp + "/perUseGoods/getPreUseGoodsRecord", {
        hasWx: true,
        userId: this.userId,
        page: 1,
        size: 1
      }).then(res => {
        if (res.data.records && res.data.records.length) {
          this.showFloat = true;
        }
      })
    },
    paySuccessCallback() {
      // this.$toast('支付成功')
      setTimeout(() => {
        this.showPayPopup=false;
        this.perUseVenueId = ''
        this.perVenueName = ''
        this.getGoodStatus()
      }, 500)
    },
    showShare() {
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }

      this.isShareShow = true
    },

    initQr() {
      this.$axios
        .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
          token: this.token,
          userId: this.userId,
          width: 240,
          height: 240,
          webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
          scene: JSON.stringify({
            source: `activity_${this.shopId}`,
            id: this.shopId,
            webUrl: webPath,
          }),
        })
        .then((res) => {
          this.userDetail = res.data
        })
    },
    miniShare() {
      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      })
    },
    openRule(isTotalRule) {
      this.curRule = isTotalRule ? this.AI.shopStyle.ruleContent : this.curGoods.saleRule
      this.isRuleShow = true
    },
    close() {
      this.isItemConfirmShow = false
      this.isRenewPayTypeShow = false
      this.isItemSelectShow = false
      this.itemIndex = 0
      this.isZhimaxianxiang = false
      this.isHasActivedCardShow = false
      this.isCombinedBuyShow = false
    },
    getHeight() {
      this.$nextTick(() => {
        this.navHeight = this.$refs.nav ? this.$refs.nav.clientHeight : 0
        this.groupHeight = this.$refs.groupBox ? this.$refs.groupBox.clientHeight + 5 : 0;
      })
    },
    toGroup(el, index) {
      const refBox = this.$refs.refContent;
      this.groupIndex = index
      const ref = this.$refs[el]
      console.log(ref[0].offsetTop)
      this.isClickScroll = true
      clearTimeout(this.timer2);
      this.timer2 = setTimeout(() => {
        this.isClickScroll = false
      }, 1000)
      window.scrollTo({
        left: 0,
        top: refBox.offsetTop + ref[0].offsetTop - (this.barHeight + this.navHeight + this.groupHeight),
        behavior: 'smooth',
      })
    },
    setScroll() {
      window.removeEventListener('scroll', this.handleScroll, true)
      clearTimeout(this.timer);
      this.topArr = []
      this.timer = setTimeout(() => {
        // 计算各个group的offsetTop
        this.curGoods.groupInfos.map((group) => {
          let top = this.$refs.refContent.offsetTop + this.$refs[`group${group.id}`][0].offsetTop - (this.barHeight + this.navHeight + this.groupHeight)
          this.topArr.push(top)
        })

        if (this.curGoods.showGroupMenu && this.curGoods.groupInfos.length > 1) {
          window.addEventListener('scroll', this.handleScroll, true)
        }
      }, 2000)
    },
    handleScroll(e) {
      if (e.target.nodeName !== '#document' || this.isClickScroll) return
      let top = document.documentElement.scrollTop || document.body.scrollTop
      for (let i = 0; i < this.topArr.length; i++) {
        if (top < this.topArr[i + 1]) {
          this.groupIndex = i
          break
        } else {
          this.groupIndex = this.topArr.length - 1
        }
      }
    },
    hexToRgba(hex, opacity) {
      if (!hex) return ''
      return `rgba(${parseInt('0x' + hex.slice(1, 3))}, ${parseInt('0x' + hex.slice(3, 5))}, ${parseInt('0x' + hex.slice(5, 7))}, ${opacity})`
    },
    recommendedClose() {
      this.getGoodStatus()
    },
    addressChange(obj) {
      this.address = obj
    },
    //门店弹出窗口返回的数据
    choseStorePop(val, param) {
      console.log(val, param)
      this.venueSelectShow = false
      if (param) {
        this.perUseVenueId = param.venueId[0]
        this.perVenueName = param.venueName
        this.buyWhat()
      }
    },
  },
}
</script>
<style lang="less" scoped>
.marketing-preview {
  min-height: 100vh;
}

.marketing-template-deposit {
  min-height: 100vh;
  background: #fb7a37;
  padding-bottom: 80px;

  div {
    box-sizing: border-box;
  }

  img {
    width: 100%;
  }

  .template-content {
    margin: 0 32px;
    position: relative;
    padding: 0 24px 1px;

    &::before {
      content: '';
      position: absolute;
      top: -196px;
      bottom: 0;
      left: 0;
      right: 0;
      background: inherit;
      border-radius: 50px 50px 40px 40px;
      background: #FF9D3D;
      box-shadow: 0px 0px 32px 0px #FFF4C1 inset;
      // z-index: -1;
    }
  }

  .background {
    position: relative;
    width: 100%;

    .head-img {
      display: block;
      min-height: 30px;
    }

    .top-button {
      position: absolute;
      right: 0;
      top: 210px;
      width: 88px;
      z-index: 99;

      .icon {
        width: 88px;
        height: 88px;
        object-fit: contain;
        margin-bottom: 32px;
      }

      .button {
        line-height: 56px;
        background: rgba(36, 40, 49, 0.3);
        border-radius: 40px 0 0 40px;
        color: #fff;
        width: 100%;
        text-align: center;
        margin-bottom: 32px;
        padding-left: 6px;
      }
    }

    .float-icon {
      position: absolute;
      right: 0;
      bottom: 92px;
      width: 120px;
      z-index: 99;
    }
  }

  .invite-block {
    width: 686px;
    height: 104px;
    margin: 30px 32px 0;
    background: url(https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_bg_invite.png);
    background-size: 100% 100%;
    padding: 0 36px;
    color: #eeeeee;

    .invite-head {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
      background: #f5f5f5;
      flex-shrink: 0;
      margin-right: 20px;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .banner-block {
    width: 100%;
    margin: 0 0;
    padding: 0 32px;

    .small-banner {
      width: 333px;
    }
  }

  .goods-title {
    width: 427px;
    display: block;
    margin: 70px auto 12px;
  }

  .nav-title {
    margin: 48px 0 0px;
    position: relative;
    z-index: 9;
  }

  .nav {
    position: relative;
    padding: 0 56px;
    height: 148px;
    z-index: 9;

    .tm {
      flex: 1;
      background: linear-gradient(180deg, #ffffff 0%, #ffe9e3 100%);
      border-radius: 12px;
      height: 100px;
      padding-left: 26px;
      color: #383e43;
      position: relative;
      overflow: hidden;
      margin-right: 16px;

      &:last-of-type {
        margin-right: 0;
      }

      &.small {
        max-width: 218px;
        min-width: 216px;
      }

      &.active {
        color: #9b270c;
        background: linear-gradient(180deg, #fffed8 0%, #ffe293 100%);
      }

      .ii {
        transform: scale(0.8);
        transform-origin: 0 50%;
        white-space: nowrap;
      }
    }
  }

  .van-sticky--fixed .nav {
    background: #FF3F1A;
  }

  .title-line {
    width: 100%;
    color: #fff;
    padding: 0px 0 20px;
    position: relative;
    z-index: 9;

    .arrow {
      width: 21px;
    }

    .ss {
      margin-right: 4px;
    }

    .triangle {
      width: 21px;
      margin-left: 10px;
    }
  }

  .subfield-name {
    font-size: 34px;
    color: #FFFAD7;
    // text-stroke: 2px #F83F1A;
    // background: linear-gradient(0deg, #FFFAD7 0.3662109375%, #FFFEEB 99.2919921875%);
    // -webkit-text-stroke: 2px #F83F1A;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    text-shadow: -2px -2px 0 #F83F1A,
      2px -2px 0 #F83F1A,
      -2px 2px 0 #F83F1A,
      2px 2px 0 #F83F1A;
  }

  .end {
    text-align: center;
    color: #fff;
    position: relative;
    margin-top: 40px;

    &::after,
    &::before {
      content: '';
      vertical-align: middle;
      display: inline-block;
      width: 60px;
      height: 1px;
      background: #fff;
    }

    &::after {
      margin: -2px 0 0 29px;
    }

    &::before {
      margin: -2px 29px 0 0;
    }
  }

  .group-bar {
    height: 104px;
    padding-bottom: 24px;
    position: relative;
    z-index: 99;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    .group-bg {
      width: 100%;
      display: flex;
      margin: 0 auto;
      max-width: 638px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.2);
      padding: 8px;
    }

    .item {
      width: 20%;
      color: #fff;
      border-radius: 20px;
      padding: 12px 0;
      height: 64px;

      &.active {
        background: linear-gradient(0deg, #FFFFFF 0%, #FFE9E3 100%);
        color: #F9624E;
      }
    }
  }

  .van-sticky--fixed .group-bar {
    background: #FF3F1A;
  }

  .more {
    display: block;
    margin: 40px 32px;
  }

  .pop-agreement {
    border-radius: 12px;
    width: 630px;
    padding: 50px 60px;

    .a-title {
      font-weight: 600;
      font-size: 36px;
      color: #242831;
      line-height: 44px;
      text-align: center;
    }

    .a-content {
      font-size: 26px;
      color: #010101;
      line-height: 40px;
      margin: 40px 0 30px;
      height: 650px;
      overflow: auto;

      .line {
        margin-top: 10px;
      }
    }

    .a-ok {
      height: 96px;
      background: #FFDE00;
      border-radius: 8px;
      font-weight: 600;
      font-size: 32px;
      color: #242831;
      line-height: 96px;
      text-align: center;
    }
  }



  .ad-group {
    .ad-item {
      width: 100%;
      margin: 0 0 32px;
    }
  }

  .good-group {
    // border-radius: 0px 0px 40px 40px;
    // padding: 24px;
    margin-bottom: 40px;
    min-height: 280px;
    position: relative;


    &.only-img {
      background: none;
      padding-left: 0;
      padding-right: 0;
    }

    .two-img {
      width: 50%;
    }

    .three-img {
      width: 33.33%;
    }
  }

  .group-name {
    color: #fff;
    padding: 0 0 0 18px;
    position: relative;
    margin-bottom: 20px;

    &:first-of-type {
      margin-top: 12px;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 8px;
      height: 28px;
      background: linear-gradient(0deg, #FFFED8 0%, #FFE293 100%);
      border-radius: 4px;
    }
  }


  .pop-rule {
    border-radius: 24px 24px 0 0;
    color: #242831;

    .pop-rule-title {
      height: 140px;
      text-align: center;
      line-height: 130px;
      position: relative;
      background: linear-gradient(0deg, #FFFFFF 0%, #FFD6C0 100%);

      .pop-rule-close {
        position: absolute;
        right: 32px;
        top: 38px;
        width: 44px;
        height: 44px;
        line-height: 44px;
        border-radius: 50%;
        background: rgba(39, 40, 49, 0.2);
      }
    }

    .pop-rule-content {
      padding: 0 52px;
      max-height: 600px;
      overflow: auto;
      padding-bottom: 60px;
      white-space: pre-line;
    }
  }

  .pop-sure {
    width: 610px;
    padding: 0 30px 32px;
    overflow: visible;
    border-radius: 40px 40px 20px 20px;
    transform-style: preserve-3d;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      height: 220px;
      background: linear-gradient(0deg, #FFFFFF 0%, #FFF5DD 100%);
      border-radius: 40px 40px 0px 0px;
      z-index: -1;
    }

    .sure-title {
      color: #242831;
      text-align: center;
      font-size: 36px;
      line-height: 1;
      padding: 40px 0;
    }

    .pay-model {
      display: flex;
      background: #FFF5F0;
      border: 3px solid #FFAC7F;
      border-radius: 16px;
      position: relative;
      padding: 20px;

      .pay-pic {
        width: 165px;

        img {
          border-radius: 10px;
          overflow: hidden;
        }
      }

      .pay-info {
        flex: 1;
        margin-left: 16px;

        .pay-name {
          font-weight: 600;
          font-size: 28px;
          color: #1E242B;
          line-height: 44px;
        }

        .pay-time {
          font-weight: 400;
          font-size: 20px;
          color: #242831;
        }

        .nosend {
          width: 100px;
          height: 30px;
          background: linear-gradient(90deg, #FFE8B5 0%, #FFD7C3 100%);
          border-radius: 4px;
          font-weight: 500;
          font-size: 20px;
          color: #B12E1D;
          line-height: 1;
          padding-top: 6px;
          text-align: center;
          margin-top: 12px;
        }

        .pay-price {
          font-weight: 600;
          font-size: 28px;
          color: #F03C18;
          line-height: 44px;

          .after-price {
            font-size: 18px;
            margin-right: 7px;
          }
        }
      }
    }

    .start {
      font-weight: 600;
      font-size: 22px;
      color: #FF3600;
      line-height: 1;
      margin: 25px 0 19px
    }

    .sure-tip {
      margin: 0 0 12px;
    }

    .sure-sub {
      border-radius: 8px;
      background: #ffde00;
      color: #000;
      line-height: 96px;
      text-align: center;
      margin: 28px 0 31px;
      font-weight: bold;
    }

    .ag {
      font-size: 22px;
      color: #000000;
      line-height: 1;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      .info {
        width: 26px;
        height: 26px;
        margin-right: 7px;
      }
    }

    .xy {
      color: #267DFF;
      font-weight: bold;
    }

    .coner {
      position: absolute;
      right: -4px;
      top: -24px;
      padding: 0 12px;
      height: 40px;
      border-radius: 10px;
      font-weight: bold;
      font-size: 24px;
      color: #FFFFFF;
      line-height: 1;
      display: flex;
      align-items: center;
      z-index: 1;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(0deg, #6F86D6 0%, #48C6EF 100%);
        border-radius: inherit;
        z-index: -1;
      }

      &::after {
        content: '';
        position: absolute;
        left: 20px;
        top: 100%;
        background: #6F86D6;
        width: 20px;
        height: 20px;
        transform: skew(0, -20deg) rotate(-15deg);
        margin-top: -16px;
        z-index: -2;
      }
    }

    .pay-close {
      position: absolute;
      top: 100%;
      margin-top: 60px;
      text-align: center;
      width: 100%;

      img {
        width: 60px;
        height: 60px;
        margin-right: 30px;
      }
    }
  }


  .error-box {
    width: 630px;
    background: #ffffff;
    border-radius: 12px;
    margin: 0 auto;
    padding: 0 40px 48px;

    h4 {
      text-align: center;
      padding-top: 40px;
    }

    p {
      margin: 32px auto 56px;
      width: 510px;
      color: #3c454e;
      line-height: 44px;
    }

    .btn {
      margin: 0 auto;
      width: 510px;
      height: 96px;
      background: #ffde00;
      border-radius: 8px;
    }

    span {
      color: #f03c18;
    }
  }

  @bei: 1px;

  .canvascss {
    position: fixed;
    bottom: -200%;
    // top: 0;
    width: @bei*600;
    height: @bei*1066;
    margin: 0 auto;
    box-sizing: border-box;

    .canvascss_bg {
      width: 100%;
      height: 100%;
    }

    .canvascss_left {
      position: absolute;
      left: @bei*26;
      bottom: @bei*30;
      z-index: 1;
      width: @bei*100;
      height: @bei*100;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .canvascss_name {
      position: absolute;
      left: @bei*150;
      bottom: @bei*110;
      z-index: 1;
      color: #fff;
      width: @bei*252;
    }

    .canvascss_right {
      position: absolute;
      right: @bei*34;
      bottom: @bei*28;
      width: @bei*124;
      height: @bei*124;
      overflow: hidden;
      //background: url(https://img.chaolu.com.cn/ACT/anniversary-2023/border.png);
      background-size: 100% 100%;
      padding: @bei*12;

      img {
        border-radius: @bei*8;
        width: 100%;
        height: 100%;
      }
    }
  }

  .red {
    color: #ee4832;
  }

  /deep/.canvas-box {
    padding: 0 40px !important;
    box-sizing: border-box;

    .create-img {
      width: 100% !important;
    }
  }
}

/deep/ .red {
  color: #ff6e00;
}
</style>
